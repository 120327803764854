/**
 * @name buildQuery
 * @param {object} data
 * @returns {string}
 */
const buildQuery = function buildQuery(data) {
	// Create an array to hold the key/value pairs
	let query = [];

	// Loop through the data object
	for (let key in data) {
		if (data.hasOwnProperty(key)) {
			if (Array.isArray(data[key])) {
				data[key].forEach(function (value) {
					query.push(key + '=' + encodeURIComponent(value));
				});
			} else {
				query.push(key + '=' + encodeURIComponent(data[key]));
			}
		}
	}

	// Join each item in the array with an '&' and return the resulting string
	query = query.join('&');

	return query;
};

/**
 * @name closePlayButton
 * @param {string} selector
 */
const closePlayButton = function closePlayButton(dataSelector) {
	return `<button class="close-button" aria-label="Close pop-up"${((dataSelector) ? ' data-selector="' + dataSelector + '"' : '')} tabindex="0">${iconTemplate('close')}</button>`;
};

/**
 * @name dirButton
 * @param {string} style
 * @param {string} label
 */
const dirButton = function dirButton(style, label) {
	return `<button class="dir-button${((style !== '') ? (' ' + style) : '')}" aria-label="${label}" tabindex="0">${iconTemplate('chevron')}</button>`;
};

/**
 * @name getData
 * @param {string} url
 * @param {string} data
 * @param {string} type
 * @returns {object}
 */
const getData = function getData(url, data, type) {
	const deferred = $.Deferred();

	$.ajax({
		url: url,
		method: 'GET',
		data: data,
		dataType: type,
		success(data) {
			deferred.resolve(data);
		},
		error(jqXHR, textStatus) {
			deferred.reject(textStatus);
		}
	});

	return deferred.promise();
};

/**
 * @name iconTemplate
 * @param {string} name
 */
const iconTemplate = function iconTemplate(name) {
	return `
		<svg class="icon icon-${name}" aria-hidden="true">
			<use xlink:href="#icon-${name}"></use>
		</svg>
	`;
};

/**
 * @name mql
 * @const {object}
 */
const mql = {
	small: window.matchMedia('(min-width: 0px)'),
	medium: window.matchMedia('(min-width: 768px)'),
	large: window.matchMedia('(min-width: 1024px)'),
	xlarge: window.matchMedia('(min-width: 1280px)'),
	xxlarge: window.matchMedia('(min-width: 1440px)'),
	primarynav: window.matchMedia('(min-width: 1366px)'),
};

/**
 * @name queryStringToOjbect
 * @param {string} qs
 * @returns {object}
 */
const queryStringToJSON = function queryStringToJSON(qs) {
	qs = qs || window.location.search.slice(1);

	let pairs = qs.split('&');
	let result = {};

	pairs.forEach(function (p) {
		let pair = p.split('=');
		let key = pair[0];
		let value = decodeURIComponent(pair[1] || '');

		if(result[key]) {
			if(Object.prototype.toString.call(result[key]) === '[object Array]') {
				result[key].push(value);
			} else {
				result[key] = [result[key], value];
			}
		} else {
			result[key] = value;
		}
	});

	return JSON.parse(JSON.stringify(result));
};

/**
 * @name ready
 * @param {func} callbackFunc
 * @description On DOM ready, execute callback function
 */
const ready = function ready(callbackFunc) {
	// Document is ready; call the callback function
	if (document.readyState !== 'loading') {
		callbackFunc();
	// Modern browsers
	} else if (document.addEventListener) {
		document.addEventListener('DOMContentLoaded', callbackFunc, false);
	// Old IE browsers (IE <= 8)
	} else {
		document.attachEvent('onreadystatechange', function () {
			if (document.readyState === 'complete') {
				callbackFunc();
			}
		});
	}
};

/**
 * @name scrollToTop
 * @param {object} element
 * @param {number} duration
 */
const scrollToTop = function scrollToTop(element, duration) {
	$('html, body').animate({
		scrollTop: element.offset().top
	}, duration);
};

const toggleVideoPlay = function toggleVideoPlay(e) {
	const $target = $(e.target);
	const $video = $target.parent().find('.video-container').children('video')[0];

	$target.toggleClass('pause-button--is-paused');
	$target.attr({
		'aria-pressed': function(i, txt) {
			return (txt === 'false') ? 'true' : 'false';
		},
		'aria-label': function (i, txt) {
			return (txt === 'Pause video') ? 'Play video' : 'Pause video';
		}
	});

	if ($target.hasClass('pause-button--is-paused')) {
		$video.pause();
	} else {
		$video.play();
	}
};

const utils = {
	buildQuery,
	closePlayButton,
	dirButton,
	getData,
	iconTemplate,
	mql,
	queryStringToJSON,
	ready,
	scrollToTop,
	toggleVideoPlay,
};

export { buildQuery, closePlayButton, dirButton, getData, iconTemplate, mql, queryStringToJSON, ready, scrollToTop, toggleVideoPlay };
export { utils as default };