const search = {

	$window: $(window),

	elem: {
		$closeButton: $('.search__close-button'),
		$focusable: $('button:not(".search__submit-button, .search__close-button, .search-trigger"), [href], input:not(".search__input"), select, textarea, [tabindex]:not([tabindex="-1"])'),
		$search: $('#search'),
		$searchTrigger: $('#search-trigger')
	},

	init() {
		this.placeholder = this.elem.$search.find('.search__input').attr('placeholder'); // Get placeholder value

		this.bindUIActions();
		this.onResize(window.matchMedia('(min-width: 1366px)')); // On page load...
	},

	bindUIActions() {
		this.$window.on('keydown', this.onKeyDown.bind(this));

		this.elem.$search.on('click', '#search__input', (e) => {
			$(e.target).attr('placeholder', '');
		});

		$(document).on('click', (e) => {
			let isEmpty = this.elem.$search.find('.search__input').val().length === 0;

			if ($(e.target).hasClass('search__input')) {
				return;
			}

			if (isEmpty) {
				this.elem.$search.find('.search__input').attr('placeholder', this.placeholder);
			}
		});

		this.elem.$closeButton
			.on('click', this.onClick.bind(this))
			.on('mouseleave', e => $(e.target).blur())

		this.elem.$searchTrigger
			.on('click', this.onClick.bind(this))
			.on('mouseleave', e => $(e.target).blur());

		window.matchMedia('(min-width: 1366px)').addListener(this.onResize.bind(this));
	},

	onClick() {
		this.elem.$searchTrigger.toggleClass('search-trigger--is-active');

		this.elem.$focusable.attr('tabindex', (i, txt) => (txt === '-1') ? null : '-1');

		$('.search__button, .search__close-button, .search__trigger').attr('tabindex', (i, txt) => (txt === '0') ? null : '0');

		$('html, body').toggleClass('body--no-scroll'); // Prevent vertical scrolling

		this.elem.$search
			.toggleClass('search--is-visible')
			.one('transitionend', e => {
				const $input = $(e.target).find('.search__input');

				if ($(e.target).hasClass('search--is-visible')) {
					$input.focus();
				} else {
					this.elem.$searchTrigger.focus();
				}
			});
	},

	onKeyDown(e) {
		const code = (e.keyCode ? e.keyCode : e.which);

		if (e.key === 'Escape' || code === 27) {
			if (this.elem.$searchTrigger.hasClass('search-trigger--is-active') && this.elem.$search.hasClass('search--is-visible')) {
				this.elem.$closeButton.trigger('click');
			}
		}
	},

	onResize(mq) {
		this.elem.$search.attr('class', () => (mq.matches) ? 'search search--is-animatable' : 'search');
	}

};

export { search };