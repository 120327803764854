const secondaryNav = {

	$window: $(window),

	elem: {
		$secondaryNav: $('.secondary-nav:not(.secondary-nav--is-mobile)')
	},

	init() {
		this.$window.on('keydown', this.onKeyDown.bind(this));
	},

	onKeyDown(e) {
		const code = (e.keyCode ? e.keyCode : e.which);

		let item;
		let i;

		if (window.matchMedia('(min-width: 1366px)').matches) {
			if ($(document.activeElement).closest('.secondary-nav').length > 0) {
				if (code === 37 || code === 39) {
					if (code === 37) { // Left arrow (37)
						item = $(document.activeElement).closest('.secondary-nav__item').prev();
					} else if (code === 39) { // Right arrow (39)
						item = $(document.activeElement).closest('.secondary-nav__item').next();
					}

					if (item.length > 0) {
						// Return to the first index or element within the list
						if (item.index() === ($('.secondary-nav__item', this.elem.$secondaryNav).length)) {
							item = $('.secondary-nav__item', this.elem.$secondaryNav).eq(0);
						}

						item.find('.secondary-nav__link').focus();
					} else {
						i = (item.index() === -1 && code === 37) ? ($('secondary-nav__item', this.elem.$secondaryNav).length - 1) : 0;

						$('.secondary-nav__item', this.elem.$secondaryNav).eq(i).find('.secondary-nav__link').focus();
					}
				}
			}
		}
	}

};

export { secondaryNav };