import { ready } from './lib/utils';
import { alert } from './modules/alert';
import { careerPath } from './modules/careerPath';
import { forms } from './modules/forms';
import { globalHeader } from './modules/globalHeader';
import { modal } from './modules/modal';
import { newsCard } from './modules/newsCard';
import { primaryNav } from './modules/primaryNav';
import { secondaryNav } from './modules/secondaryNav';
import { search } from './modules/search';
import { slider } from './modules/slider';

const main = {

	init() {
		alert.init();
		careerPath.init();
		forms.init();
		globalHeader.init();
		newsCard.init();
		modal.init();
		primaryNav.init();
		secondaryNav.init();
		search.init();
		slider.init();
	}

};

ready(function () {
	main.init();
});
