import { scrollToTop } from '../lib/utils';

const forms = {

	sel: {
		selects: '.form select:not([multiple])'
	},

	init() {
		this.bindUIActions();
		this.selectability();
	},

	bindUIActions() {
		$('body').on('click', '.form input[type="submit"], .form button[type="submit"]', (e) => {
			let $form = $(e.target).parents('form');

			$form.addClass('js-was-submitted');

			if (!$form.hasClass('js-is-valid')) {
				e.preventDefault();
			}

			this.checkSelectBoxesIfValid();

			if (this.checkFormValid()) {
				$form.addClass('js-is-valid');
				$form[0].submit();
			} else {
				let $field = $('[required]:invalid').first().closest('.form__field');

				scrollToTop($field, 425);
			}
		}).on('change', this.sel.selects, () => {
			this.checkSelectBoxesIfValid();
		});

		// Email fields have more strict validation so just having a value doesn't make them valid.
		// We need to elevate the label if they have any value, so it doesn't sit on top of text if they focus away.
		$('.form input[type="email"]').on('change keyup', function () {
			let val = $(this).val();
			let parent = $(this).parents('.form__field');

			if (val !== '') {
				parent.addClass('form__field--has-value');
			} else {
				parent.removeClass('form__field--has-value');
			}
		});

		$('form').on('mouseleave', 'label', this.onMouseleave.bind(this));

	},

	selectability() {
		if ($(this.sel.selects).length > 0) {
			$(this.sel.selects).selectability();

			$('.selectability[aria-disabled="true"]').attr('tabindex', '-1');
		}
	},

	checkSelectBoxesIfValid() {
		if ($('.js-was-submitted').length > 0) {
			let $selects = $(this.sel.selects);

			$selects.each(function () {
				if (typeof $(this).attr('required') !== 'undefined') {
					if ($(this).is(':invalid')) {
						$(this).parents('.form__field').addClass('form__field--invalid');
					} else {
						$(this).parents('.form__field').removeClass('form__field--invalid');
					}
				}
			});
		}
	},

	checkFormValid() {
		let $invalid = $('.form [required]:invalid');

		return ($invalid.length > 0) ? false : true;
	},

	onMouseleave(e) {
		$(`input[id="${$(e.target).attr('for')}"]`).blur();
	}

};

export { forms };
