const newsCard = {

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		$('.news-card, .results').on('mouseover mouseout focus blur', '.news-card__link, .news-card__media', this.toggleState.bind(this));
	},

	toggleState(e) {
		const $card = $(e.target).closest('.news-card');

		$card.toggleClass('news-card--is-active', ($card.hasClass('news-card--is-active')) ? false : true);
	}

};

export { newsCard };