import { mql } from '../lib/utils';

const slider = {

	elem: {
		$slider: $('.slider-wrapper:not(.slider-wrapper--type-image-caption) .slider')
	},

	options: {
		adaptiveHeight: true,
		arrows: false,
		dots: false,
		draggable: false,
		fade: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 425,
		infinite: false,
		rows: 0,
		responsive: [
			{
				breakpoint: 1023,
				settings: 'unslick'
			}
		]
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$slider
			.on('init', this.onSliderInit)
			.on('init reinit afterChange', this.onSliderChange);

		this.elem.$slider.slick(this.options);

		mql.large.addListener((mq) => {
			if (mq.matches) {
				if (!this.elem.$slider.hasClass('slick-initialized')) {
					this.elem.$slider.slick(this.options);
				}
			}
		});
	},

	onSliderInit(event, slick) {
		const $slider = slick.$slider;
		const $wayfinder = $slider.parent().find('.wayfinder');

		slick.$slides.each(() => {
			$(this).removeClass('slider__slide--is-hidden');
		});

		$wayfinder.on('click', '.wayfinder__dir-button', (e) => {
			const $target = $(e.target);
			const direction = $target.data('dir');

			if (direction === 'previous') {
				$slider.slick('slickPrev');
			} else {
				$slider.slick('slickNext');
			}
		});
	},

	onSliderChange(event, slick, currentSlide) {
		const $slider = slick.$slider;
		const $wayfinder = $slider.parent().find('.wayfinder');
		const $current = $wayfinder.find('.wayfinder__current');
		const $totalCount = $wayfinder.find('.wayfinder__total-count');

		const i = ((currentSlide) ? currentSlide : 0) + 1;

		$current.text(i);
		$totalCount.text(slick.slideCount);

		$('.wayfinder__dir-button', $wayfinder).removeClass('wayfinder__dir-button--is-disabled');

		if (i === 1) {
			$('.wayfinder__dir-button--is-prev', $wayfinder).addClass('wayfinder__dir-button--is-disabled');
		} else if (i === slick.slideCount) {
			$('.wayfinder__dir-button--is-next', $wayfinder).addClass('wayfinder__dir-button--is-disabled');
		}
	}

};

export { slider };