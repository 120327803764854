import { closePlayButton } from '../lib/utils';

const modal = {

	elem: {
		$playButton: $('.play-button')
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		$(document).on('click', '.close-button', function (e) {
			e.preventDefault();

			const selector = $(this).data('selector');
			$('.' + selector).modaal('close');
		});

		this.elem.$playButton.on('mouseleave', e => $(e.target).blur());

		this.elem.$playButton.modaal({
			type: 'video',
			hide_close: true,
			animation_speed: 425,
			after_open: function () {
				const closeButton = closePlayButton('play-button');
				$('.modaal-video-container').append(closeButton);
			},
			after_close: function () {
				this.elem.$playButton.trigger('mouseleave');
			}.bind(this)
		});
	}

};

export { modal };