const globalHeader = {

	options: {
		duration: 425,
		swing: 'swing'
	},

	elem: {
		$globalHeader: $('#global-header'),
		$hamburger: $('#hamburger'),
		$secondaryNav: $('.secondary-nav'),
		$secondaryNavMobile: $('.secondary-nav--is-mobile')
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$globalHeader
			.on('click', '#hamburger', this.menuToggle.bind(this))
			.on('mouseleave', e => $(e.target).blur());

		this.elem.$secondaryNavMobile.on('focusout', '.secondary-nav__link', this.onFocusout.bind(this));
	},

	menuToggle(e) {
		const $target = $(e.target);
		const $menu = this.elem.$globalHeader.find('.global-header__menu');

		$target
			.attr('aria-expanded', (i, txt) => (txt === 'false') ? 'true' : 'false')
			.attr('aria-label', (i, txt) => (txt.indexOf('Open') > -1) ? txt.replace('Open', 'Close') : txt.replace('Close', 'Open'))
			.toggleClass('hamburger--is-active');

		$menu.slideToggle(this.options.duration, this.options.easing).promise().done(function () {
			if ($menu.is(':visible')) {
				$('#search__input').focus();
			}
		});
	},

	onFocusout(e) {
		if ($(e.target).parent().index() === ($('.secondary-nav__item', this.elem.$secondaryNavMobile).length - 1)) {
			if (!window.matchMedia('(min-width: 1366px)').matches) {
				this.elem.$hamburger.trigger('click');
			}
		}
	}

};

export {globalHeader};
