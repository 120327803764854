/**
 * Alert component.
 * @module components/alert
*/
const alert = {

	options: {
		duration: 425,
		easing: 'swing'
	},

	elem: {
		$alert: $('#alert')
	},

	init() {
		this.bindUIActions();
		this.isActive(
			(this.elem.$alert.find('.alert__panel').data('is-active'))
		);
	},

	bindUIActions() {
		this.elem.$alert
			.on('click', '.alert__trigger', this.onClick.bind(this))
			.on('mouseleave', '.alert__trigger', (e) => $(e.target).blur());
	},

	onClick(e) {
		e.preventDefault();

		const $content = $(e.target).closest('.alert__panel').find('.alert__content').first();
		const $panel = $(e.target).closest('.alert__panel');

		// Current target
		$(e.target).attr('aria-expanded', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');
		$(e.target).attr('aria-pressed', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');

		// Panel
		$panel.toggleClass('alert__panel--is-active');
		$panel.attr('data-is-active', (i, currentValue) => (currentValue === 'false') ? 'true' : 'false');

		// Content
		$content.attr('aria-hidden', (i, currentValue) => (currentValue === 'true') ? 'false' : 'true');
		$content.slideToggle(this.options.duration, this.options.easing);
	},

	isActive(active) {
		if (!active) {
			return;
		}

		this.elem.$alert.find('.alert__trigger').trigger('click');
	}

};

export { alert };
