import { mql } from '../lib/utils';

const careerPath = {

	elem: {
		$careerPath: $('#career-path'),
		$tiles: $('.tile')
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$tiles
			.on('focus blur', this.toggleState.bind(this))
			.on('mouseover mouseout', this.toggleState.bind(this));
	},

	toggleState(e) {
		const index = this.elem.$tiles.index($(e.target));

		if (mql.medium.matches) {
			$('.career-path__media', this.elem.$careerPath).removeClass('career-path__media--is-visible');

			if (e.type === 'focus' || e.type === 'mouseover' && index > -1) {
				$('.career-path__media--' + (index + 1), this.elem.$careerPath).addClass('career-path__media--is-visible');
			} else if (e.type === 'blur' || e.type === 'mouseout' && index === 0) {
				$('.career-path__media--0', this.elem.$careerPath).addClass('career-path__media--is-visible');
			} else {
				$('.career-path__media--0', this.elem.$careerPath).addClass('career-path__media--is-visible');
			}
		}
	}

};

export { careerPath };